import $ from 'jquery';
import { Modal } from 'bootstrap';

export const NewsletterConsent = () => {
    const newsletterConsentModal = new Modal($('#newsletter-consent-modal'), { keyboard: false });
    const lang = $("#language-code").val();
    $(".join-now-btn, .email-btn").on("click", function() {
        let emailValue = $("#footer-email").val();
        let sidebarValue = $("#sidebar-email").val();
        $("#newsletter-email").val(emailValue || sidebarValue);
        newsletterConsentModal.show();
    });


    $("#newsletter-subscribe").on('click', function (e) {
        e.preventDefault();
        const checkboxes = document.querySelectorAll('#newsletter-form-on-popup input[type="checkbox"]');
        let isChecked = false;
        // Check if at least one checkbox is selected
        for (let checkbox of checkboxes) {
            if (checkbox.checked) {
                isChecked = true;
                break;
            }
        }

        if(!isChecked) {
            $("#select-error").text(lang == 'fr-ca'? 'Veuillez sélectionner au moins une option' : 'Please select at least one option');
        }else {
            $("#newsletter-form-on-popup")[0].submit();
        }
    });
};
